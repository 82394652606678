"use strict";
import Vue from 'vue';
import axios from "axios";
import router from '../router/index.js'

const env = process.env.NODE_ENV

let config = {
    timeout: 30 * 1000, // Timeout
    baseURL: env==='test'?'http://192.168.0.185:8511':'',
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        // 在请求被发送之前做些什么
        config.headers['token'] = localStorage.getItem('token')
        return config;
    },
    function (error) {
        // 处理请求错误
        return Promise.reject(error);
    }
);

// 添加响应拦截器
_axios.interceptors.response.use(
    function (response) {
        if (response.data.code === -1) {
            router.replace('/login')
            return response;
        }
        if (response.data.code === -2) {
            router.replace('/authorization')
            return response;
        }
        return response;
    },
    function (error) {
        // 处理响应错误
        return Promise.reject(error);
    }
);

Plugin.install = function (Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;
