import Vue from 'vue'
import VueRouter from 'vue-router'

const startupPage = () => import('../views/startupPage/startupPage.vue')
const login = () => import('../views/login/login.vue')
const authorization = () => import('../views/authorization/authorization.vue')

//门禁
const access_index = () => import('../views/access/layout/index.vue')
const access_home = () => import('../views/access/home/home.vue')
const access_device = () => import('../views/access/device/device.vue')
const access_person = () => import('../views/access/person/person.vue')
const access_record = () => import('../views/access/record/record.vue')
const access_statistics = () => import('../views/access/statistics/statistics.vue')

//门柜
const door_index = () => import('../views/door/layout/index.vue')
const door_home = () => import('../views/door/home/home.vue')
const door_device = () => import('../views/door/device/device.vue')
const door_person = () => import('../views/door/person/person.vue')
const door_record = () => import('../views/door/record/record.vue')
const door_openBoxRecord = () => import('../views/door/openBoxRecord/openBoxRecord.vue')
const useful_box=()=> import('../views/door/allBoxState/usefulBox.vue')

//超级管理员
const admin_index = () => import('../views/admin/layout/index.vue')
const admin_user = () => import('../views/admin/user/user.vue')
const admin_settings = () => import('../views/admin/settings/settings.vue')
const loginRecord = () => import('../views/admin/loginRecord/loginRecord.vue')

const test = () => import('../views/test/test.vue')
Vue.use(VueRouter)

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalPush.call(this, location, onResolve, onReject);
	return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalReplace.call(this, location, onResolve, onReject);
	return originalReplace.call(this, location).catch(err => err);
};


const routes = [{
		path: '/',
		name: 'startupPage',
		component: startupPage
	}, {
		path: '/login',
		name: 'login',
		component: login
	},
	{
		path: '/authorization',
		name: 'authorization',
		component: authorization
	},
	{
		path: '/access_index',
		name: 'access_index',
		component: access_index,
		children: [{
				path: '/access_home',
				name: 'access_home',
				component: access_home,
			},
			{
				path: '/access_device',
				name: 'access_device',
				component: access_device,
			},
			{
				path: '/access_person',
				name: 'access_person',
				component: access_person,
			},
			{
				path: '/access_record',
				name: 'access_record',
				component: access_record,
			},
			{
				path: '/access_statistics',
				name: 'access_statistics',
				component: access_statistics,
			},
		]
	},
	{
		path: '/door_index',
		name: 'door_index',
		component: door_index,
		children: [{
				path: '/door_home',
				name: 'door_home',
				component: door_home,
			},
			{
				path: '/door_device',
				name: 'door_device',
				component: door_device,
			},
			{
				path: '/door_person',
				name: 'door_person',
				component: door_person,
			},
			{
				path: '/door_record',
				name: 'door_record',
				component: door_record,
			},
			{
				path: '/door_openBoxRecord',
				name: 'door_openBoxRecord',
				component: door_openBoxRecord
			},
			{
				path: '/useful_box',
				name: 'useful_box',
				component: useful_box
			},
		]
	},


	{
		path: '/admin_index',
		name: '/admin_index',
		component: admin_index,
		children: [{
				path: '/admin_user',
				name: 'admin_user',
				component: admin_user
			},
			{
				path: '/admin_settings',
				name: 'admin_settings',
				component: admin_settings
			}, {
				path: '/loginRecord',
				name: 'loginRecord',
				component: loginRecord
			}
		]
	},
	{
		path: '/test',
		name: '/test',
		component: test
	}

]

const router = new VueRouter({
	routes
})

export default router
